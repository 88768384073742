import { Button } from '@/components/core/Button'
import TextField from '@/components/core/TextField'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import api from '@/api'
import { CatchPromise } from '@/utils/catch-promise'
import { showToast } from '@/utils/toastify'
import { useRouter } from 'next/router'

import { XMarkIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

export default function EmailVerifierCreditModal ({
  open = false,
  setOpen,
}) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    register,
    setValue,
  } = useForm({
    mode: 'onChange', defaultValues: {
      credits: 5000
    }
  })

  const router = useRouter()
  const currentUser = useSelector((state) => state.sessions.currentUser)

  const [emailVerifierPlans, setEmailVerifierPlans] = useState([])
  const [fetchingEmailVerifierPlans, setFetchingEmailVerifierPlans] = useState(false)
  const [buyCredits, setBuyCredits] = useState(5000)

  const calculatePrice = (credits, currency) => {
    // Validate inputs
    if (credits <= 0 || emailVerifierPlans.length === 0) {
      return;
    }

    // Find the appropriate plan
    const plan = emailVerifierPlans.find(
      plan => credits >= plan.min_credit_size && credits <= plan.max_credit_size
    );

    if (!plan) {
      return;
    }

    // Calculate base price
    const pricePerCredit = currency === "INR"
      ? parseFloat(plan.inr_price_per_credit)
      : parseFloat(plan.usd_price_per_credit);

    const basePrice = credits * pricePerCredit;

    // Add GST for INR
    if (currency === "INR") {
      const gst = basePrice * 0.18;
      return {
        currency: currency,
        gst: parseFloat(gst.toFixed(2)),
        totalPrice: parseFloat((basePrice + gst).toFixed(2))
      };
    }

    // Return price for USD
    return {
      currency: currency,
      totalPrice: parseFloat(basePrice.toFixed(2))
    };
  };

  const amountWithGst = useMemo(() => {
    return calculatePrice(buyCredits, currentUser.company?.currency)
  }, [buyCredits, currentUser.company?.currency, emailVerifierPlans])

  async function payNow (order) {
    var options = {
      key: process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: order.currency,
      name: "CampaignHQ", //your business name
      description: "WhatsApp Recharge",
      order_id: order.providerId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const verifyParams = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
        }
        const [err, res] = await CatchPromise(
          api.paymentOrders.verify(order.id, verifyParams)
        )
        if (res) {
          showToast('success', 'Success', 'Payment successful')
          router.reload()
        }
      },
      modal: {
        ondismiss: function () {
          console.log('Payment cancelled')
        }
      }
    }

    var rzp1 = new Razorpay(options)

    rzp1.on('payment.failed', function (response) {
      console.log(response.error);
    });

    rzp1.open();
  }

  const closeModal = () => {
    setOpen(false)
  }

  const fetchEmailVerifierPlans = async () => {
    setFetchingEmailVerifierPlans(true)
    const [err, res] = await CatchPromise(api.emailVerifierPlans.index())
    if (res) {
      setEmailVerifierPlans(res.data)
    }
    setFetchingEmailVerifierPlans(false)
  }

  const submit = async function (data) {
    const [err, res] = await CatchPromise(api.paymentOrders.createEmailVerifierOrder(data))

    if (res) {
      payNow(res.data)
      closeModal()
    }
  }

  useEffect(() => {
    fetchEmailVerifierPlans()
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-1/3 transform rounded-lg bg-white text-left shadow-xl transition-all">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="flex space-x-2 text-lg font-semibold leading-6 text-gray-900">
                      <div>
                        Buy Email Verification Credits
                      </div>
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      You can check the cost per email verification <a href="https://docs.google.com/spreadsheets/d/1_0iojlv7Dv_U5XMwFWLl_os5PfRSFhiyaP6VPswmk60/edit?usp=sharing" target='_blank' className='text-indigo-600 underline'>here</a>
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <form onSubmit={handleSubmit(submit)}>
                      <TextField
                        name="credits"
                        register={register}
                        validations={{
                          required: 'Amount is required',
                          min: {
                            value: 5000,
                            message: 'Minimum credits is 5000',
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: 'Please enter a number',
                          },
                        }}
                        error={errors?.credits}
                        label="How many email credits do you need?"
                        type="number"
                        placeholder="5000"
                        onChange={(value) => {
                          setBuyCredits(value)
                          setValue('credits', value)
                        }}
                      />

                      {(currentUser.company.currency == 'INR') ? (<div className='mt-5'>
                        <span className='text-gray-600'>Total amount (Buy amount + 18% GST):</span> <span className='font-semibold'>₹{amountWithGst?.totalPrice?.toLocaleString()}</span>
                      </div>
                      ) : (<div className='mt-5'>
                        <span className='text-gray-600'>Buy amount:</span> <span className='font-semibold'>${amountWithGst?.totalPrice?.toLocaleString()}</span>
                      </div>)}

                      <div className="mt-5 flex justify-end">
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          disabled={isSubmitting || !isValid || fetchingEmailVerifierPlans}
                          text={'Recharge ->'}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
